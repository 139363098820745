<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <sidebar-closet></sidebar-closet>
          <div class="page__content">
            <div style="width: 100%; text-align: right;">
              <router-link type="button" class="button_bg" to="/armazenar"> Solicitar coleta </router-link>
            </div>
            <div class="tabs__flex">
              <div style="margin-right: 100px;" class="tabs__search">
                <div class="search__form">
                  <input class="form__input" type="search" placeholder="Pesquisar..." v-model="search" />
                  <button class="button search__button" type="submit">
                    <i class="icon icon-search"></i>
                  </button>
                </div>
              </div>
              <div class="tabs__filters">
                <div class="tabs__filters--group">
                  <label class="tabs__filters--label">De</label>
                  <input
                    class="form__input tabs__filters--input"
                    type="date"
                    v-model="startDate"
                  />
                </div>

                <div  class="tabs__filters--group">
                  <label class="tabs__filters--label">Até</label>
                  <input
                    class="form__input tabs__filters--input"
                    type="date"
                    v-model="endDate"
                  />
                </div>
              </div>
            </div>

            <div class="material__list">
              <div v-if="loading">Carregando dados...</div>
              <dir v-else-if="!items.length">
                Nenhum item cadastrado no momento.
              </dir>

              <div
                v-else
                v-for="(item, index) in filtered"
                :key="index"
                class="material__item"
              >
                <img
                  class="material__item--image"
                  :src="$help.image(item.photo)"
                  alt=""
                />

                <div class="material__item--content">
                  <h4>
                    {{ item.name }}
                    <span>{{ item.ammount }} {{ item.unity }}</span>
                  </h4>
                  <p>Coletado em: {{ item.collected === null ? 'sem data' : item.collected }}</p>

                  <div class="material__item--footer">
                    <span v-if="item.rating != null">Nota: {{ item.rating }}</span>
                    <a v-else
                      class="material__item--anchor"
                      href="#"
                      title="Solicitar coleta"
                      data-modal="modal-request-collection"
                      @click="handleRequestCollection(item)"
                    >
                    <i class="icon icon-star"></i> Avaliar
                    </a>
                    <a
                      v-if="item.license_number !== null"
                      style="margin-left: 50px;"
                      class="material__item--anchor"
                      :href=" url + '/collects/certificate?item_closet_id=' + item.idclosets + '&output=pdf'"
                      title="Certificado"
                    >
                    <i class="icon icon-extract"></i> Certificado
                    </a>
                  </div>
                </div>
              </div>

              <!-- page item -->
            </div>
          </div>
        </div>
        <modal
        :modal.sync="collectModal"
        @change-modal="e => (collectModal = e)"
        :selectedMaterial.sync="selectedMaterial"
      />
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import help from '@/plugins/helpers'

export default {
  data: () => ({
    loading: false,
    collectModal: false,
    search: null,
    startDate: null,
    endDate: null,
    selectedMaterial: null,
    url: help.baseURL,
    filtered: null
  }),
  components: {
    sidebarCloset: () => import('@/components/sidebar-closet'),
    Modal: () => import('@/components/rating-collection.vue')
  },
  methods: {
    ...mapActions('closet', ['fetchItems']),
    async handleRequestCollection (item) {
      this.selectedMaterial = item
      this.collectModal = true
    }
  },
  computed: {
    ...mapGetters('closet', ['items'])
  },
  async mounted () {
    this.loading = true
    this.fetchItems(3)
    this.loading = false
  },
  watch: {
    search (val) {
      const list = []
      if (val !== '') {
        this.items.forEach(element => {
          if (element.name.toLowerCase().includes(val.toLowerCase())) {
            list.push(element)
          }
        })
        this.filtered = list
      } else {
        this.filtered = this.items
      }
    },
    items (val) {
      this.filtered = val
    },
    startDate (val) {
      if (val !== null && this.endDate !== null) {
        this.fetchItems({ status: 3, startDate: val, endDate: this.endDate })
      }
    },
    endDate (val) {
      if (this.startDate !== null && val !== null) {
        this.fetchItems({ status: 3, startDate: this.startDate, endDate: val })
      }
    }
  }
}
</script>

<style scoped>
.button_bg {
    background-color: #16b666;
    padding: 11px;
    border-radius: 33px;
    color: #FFFFFF;
    font-weight: bold;
    margin-right: 0px;
    width: 168px !important;
    text-align: center;
    display: inline-block;
}
</style>
